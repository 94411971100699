import { all, put, call } from 'redux-saga/effects'
import {
  getMenuData,
  getMenuDataforManagers,
  getMenuDataforDrivers,
  getMenuDataForHOD,
  getMenuDataForSecurity,
} from 'services/menu'

export function* GET_DATA() {
  const usernameRole = localStorage.getItem('name')
  // console.log("userRole================================>",usernameRole)
  // const usernameRole = 'Driversupervisor'
  if (usernameRole === 'Administrator') {
    const menuData = yield call(getMenuData)
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData,
      },
    })
  } else if (usernameRole === 'Head of Dept') {
    const menuData = yield call(getMenuDataForHOD)
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData,
      },
    })
  } else if (usernameRole === 'SecurityAdministrator') {
    const menuData = yield call(getMenuDataForSecurity)
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData,
      },
    })
  } else if (usernameRole === 'Driversupervisor') {
    const menuData = yield call(getMenuDataforDrivers)
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData,
      },
    })
  } else {
    const menuData = yield call(getMenuDataforManagers)
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
